
































































import { Component, Vue } from 'vue-property-decorator';
import StrategyHiringViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-hiring-view-model';

@Component({ name: 'StrategyHiring' })
export default class StrategyHiring extends Vue {
  strategy_hiring_view_model = Vue.observable(new StrategyHiringViewModel(this));

  created() {
    this.strategy_hiring_view_model.initialize();
  }
}
